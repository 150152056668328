import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { useGetAllBetsQuery } from "../api";
import { AlertTriangle } from "lucide-react";
import { BASE_CONFIG } from "../app.config";

const Leaderboard: React.FC = () => {
  const { data: betHistory, isLoading, error } = useGetAllBetsQuery();

  const leaderboardWinners = useMemo(() => {
    if (!betHistory || betHistory.length === 0) return [];

    return betHistory
      .filter((bet) => bet.user !== null)
      .map((bet) => ({
        id: bet.id,
        username: bet.user.username,
        betAmount: bet.amount,
        winAmount: bet.amount * bet.crashMultiplier,
        crashMultiplier: bet.crashMultiplier,
      }))
      .sort((a, b) => b.winAmount - a.winAmount)
      .slice(0, 10);
  }, [betHistory]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading leaderboard...</LoadingText>
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <AlertTriangle size={48} color="#ff6b6b" />
        <ErrorText>Oops! We couldn't load the leaderboard.</ErrorText>
      </ErrorContainer>
    );
  }

  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";
  return (
    <LeaderboardContainer>
      <Title>Leaderboard 🏆</Title>
      {leaderboardWinners.length === 0 ? (
        <NoWinnersMessage>No winners to display</NoWinnersMessage>
      ) : (
        <WinnersList>
          {leaderboardWinners.map((winner, index) => (
            <WinnerCard key={winner.id} isTopRank={index === 0}>
              <Rank>{index + 1}</Rank>
              <Username>{winner.username}</Username>
              <WinInfo>
                <BetAmount>
                  {currencyCode} {winner.betAmount.toFixed(2)}
                </BetAmount>
                <Multiplier>x{winner.crashMultiplier.toFixed(2)}</Multiplier>
              </WinInfo>
              <WinAmount>
                {currencyCode} {winner.winAmount.toFixed(2)}
              </WinAmount>
            </WinnerCard>
          ))}
        </WinnersList>
      )}
    </LeaderboardContainer>
  );
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #4ecca3;
  border-top: 4px solid #1a1a2e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px;
`;

const LoadingText = styled.p`
  font-size: 18px;
  color: #4ecca3;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
`;

const ErrorText = styled.h3`
  font-size: 24px;
  color: #ff6b6b;
  margin: 20px 0 10px;
`;

const LeaderboardContainer = styled.div`
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffd700;
`;

const WinnersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 665px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #16213e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4ecca3;
    border-radius: 4px;
  }
`;

const WinnerCard = styled.div<{ isTopRank: boolean }>`
  background-color: ${(props) => (props.isTopRank ? "#2a3f5f" : "#16213e")};
  border-radius: 25px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s;
  margin: 5px 0;

  &:hover {
    transform: scale(1.02);
  }
`;

const Rank = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #ffd700;
`;

const Username = styled.div`
  font-weight: bold;
  color: #4ecca3;
  font-size: 14px;
`;

const WinInfo = styled.div`
  display: flex;
  align-items: center;
`;

const BetAmount = styled.span`
  color: #e0e0e0;
  font-size: 12px;
  margin-right: 5px;
`;

const Multiplier = styled.span`
  color: #ff6b6b;
  font-weight: bold;
  font-size: 12px;
`;

const WinAmount = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #4ecca3;
`;

const NoWinnersMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #e0e0e0;
  margin-top: 20px;
`;

export { Leaderboard };
