import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Trophy, Coins } from "lucide-react";

interface CashoutNotificationProps {
  isVisible: boolean;
  message: string;
  amount?: number;
  duration?: number;
  onClose?: () => void;
}
interface ParticleProps {
  index: number;
  left: string;
  delay: string;
  duration: string;
}
// Breakpoints
const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

// Media Queries
const media = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
};

// Keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideDown = keyframes`
  0% { 
    transform: translateY(-100%);
    opacity: 0;
  }
  10% { 
    transform: translateY(0);
    opacity: 1;
  }
  90% { 
    transform: translateY(0);
    opacity: 1;
  }
  100% { 
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const shine = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
`;

const particle = keyframes`
  0% { 
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% { 
    transform: translate(
      ${() =>
        `${(Math.random() - 0.5) * 100}px, ${(Math.random() - 0.5) * 100}px`}
    ) scale(0);
    opacity: 0;
  }
`;

const bounceIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components with responsive design
const NotificationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
  padding: 0 1rem; // Add padding for small screens

  ${media.sm} {
    padding: 0 2rem;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  animation: ${fadeIn} 0.3s ease-out forwards;
`;

const NotificationContainer = styled.div`
  position: relative;
  margin-top: 1rem; // Smaller margin for mobile
  padding: 1rem;
  width: 100%; // Full width on mobile
  max-width: 90vw; // Prevent extreme widths
  pointer-events: auto;
  background: linear-gradient(to bottom, #ffd700, #ffc107, #ffd700);
  border-radius: 0.75rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  animation: ${slideDown} 3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  ${media.sm} {
    margin-top: 2rem;
    padding: 1.5rem;
    max-width: 480px;
  }

  ${media.md} {
    margin-top: 4rem;
    padding: 1.5rem 2rem;
  }
`;

const ShineEffect = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  border-radius: 0.75rem;
  overflow: hidden;
  animation: ${shine} 2s infinite;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem; // Smaller gap for mobile

  ${media.sm} {
    gap: 1rem;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  animation: ${bounceIn} 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  svg {
    width: 36px; // Smaller icon for mobile
    height: 36px;

    ${media.sm} {
      width: 48px;
      height: 48px;
    }
  }
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  min-width: 0; // Prevent text overflow
`;

const Message = styled.h3`
  font-size: 1.25rem; // Smaller font for mobile
  font-weight: bold;
  color: #212529;
  margin: 0;
  animation: ${fadeSlideUp} 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  word-wrap: break-word; // Handle long words
  width: 100%;

  ${media.sm} {
    font-size: 1.5rem;
  }
`;

const AmountWrapper = styled.div<{ delay: string }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  animation: ${fadeSlideUp} 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  animation-delay: ${(props) => props.delay};

  svg {
    width: 16px; // Smaller icon for mobile
    height: 16px;

    ${media.sm} {
      width: 20px;
      height: 20px;
    }
  }

  span {
    font-size: 1rem; // Smaller font for mobile

    ${media.sm} {
      font-size: 1.25rem;
    }
  }
`;

const ParticleElement = styled.div<ParticleProps>`
  position: absolute;
  width: 6px; // Smaller particles for mobile
  height: 6px;
  background-color: #ffd700;
  border-radius: 50%;
  left: ${(props) => props.left};
  animation: ${particle} ${(props) => props.duration} ease-out infinite;
  animation-delay: ${(props) => props.delay};

  ${media.sm} {
    width: 8px;
    height: 8px;
  }
`;

const CashoutNotification: React.FC<CashoutNotificationProps> = ({
  isVisible,
  message,
  amount,
  duration = 3000,
  onClose,
}) => {
  const [show, setShow] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        onClose?.();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isVisible, duration, onClose]);

  console.log("is show cashout notification", show);
  if (!show) return null;

  return (
    <NotificationOverlay>
      <Backdrop />

      <NotificationContainer className="notification-container">
        <ShineEffect />

        <ContentWrapper>
          <IconWrapper>
            <Trophy color="#212529" />
          </IconWrapper>

          <MessageContent>
            <Message>{message}</Message>
            {amount && (
              <AmountWrapper delay="150ms">
                <Coins color="#212529" />
                <span className="fw-semibold">${amount.toLocaleString()}</span>
              </AmountWrapper>
            )}
          </MessageContent>
        </ContentWrapper>

        {/* Particles */}
        {[...Array(12)].map((_, i) => (
          <ParticleElement
            key={i}
            index={i}
            left={`${Math.random() * 100}%`}
            delay={`${Math.random() * 2}s`}
            duration={`${2 + Math.random() * 2}s`}
          />
        ))}
      </NotificationContainer>
    </NotificationOverlay>
  );
};

// Optional: Add a CSS class to handle any Bootstrap-specific overrides
const globalStyles = `
  .notification-container {
    // Override any conflicting Bootstrap styles if needed
    * {
      box-sizing: border-box;
    }
  }
`;

export { CashoutNotification };
