import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Col, Row } from "react-bootstrap";
import { BASE_CONFIG } from "../app.config";
import { SnackBarMode } from "../types";
import { Snackbar } from "../components/SnackBar";
import { useAppDispatch, useAppSelector } from "../hooks";
import { onBetDeduct, onCashoutIncrease } from "../store/slices/demo-slice";

interface DemoBetCardProps {
  betNumber: number;
  initialBalance: number;
  isGameRunning: boolean;
  currentMultiplier: number;
  onPlaceBet: (amount: number, autoCashout: number) => void;
  onCashout: () => void;
  gamePhase: "waiting" | "running" | "crashed";
}

const DemoBetCard: React.FC<DemoBetCardProps> = ({
  betNumber,
  initialBalance,
  isGameRunning,
  currentMultiplier,
  onPlaceBet,
  onCashout,
  gamePhase,
}) => {
  const demoWallet = useAppSelector(
    (state) => state.rootReducer.demo.demoWallet
  );
  const [autoCashout, setAutoCashout] = useState<boolean>(false);
  const [balance, setBalance] = useState(demoWallet);
  const [betAmount, setBetAmount] = useState(10);
  const [autoCashoutMultiplier, setAutoCashoutMultiplier] = useState(2);
  const [isBetting, setIsBetting] = useState(false);
  const [status, setStatus] = useState("pending");
  const [potentialWin, setPotentialWin] = useState(0);
  const [isQueued, setIsQueued] = useState(false);
  const dispatch = useAppDispatch();
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  useEffect(() => {
    if (gamePhase === "waiting") {
      setStatus("pending");
      if (isQueued) {
        handlePlaceBet();
        setIsQueued(false);
      }
    } else if (gamePhase === "crashed" && isBetting) {
      setStatus("Lost");
      setIsBetting(false);
    }
  }, [gamePhase, isBetting]);

  useEffect(() => {
    if (isBetting) {
      setPotentialWin(betAmount * currentMultiplier);
      if (currentMultiplier >= autoCashoutMultiplier) {
        handleCashout();
      }
    }
  }, [isBetting, currentMultiplier, betAmount, autoCashoutMultiplier]);

  const handleBetAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d+(\.\d*)?$/.test(value)) {
      setBetAmount(Number(value));
    }
  };

  const handleAutoCashoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d+(\.\d*)?$/.test(value)) {
      setAutoCashoutMultiplier(Number(value));
    }
  };

  const incrementBetAmount = () => {
    if (betAmount < balance) {
      setBetAmount(betAmount + 10);
    }
  };

  const decrementBetAmount = () => {
    if (betAmount > 0) {
      setBetAmount(betAmount - 10);
    }
  };

  const handlePlaceBet = () => {
    setSnackbar({
      show: true,
      message: "Bet Placed!!",
      type: "success",
    });
    if (gamePhase === "running") {
      setStatus("queued");
      setIsQueued(true);

      return;
    }

    if (betAmount > 0 && betAmount <= balance && gamePhase === "waiting") {
      setBalance(balance - betAmount);
      dispatch(onBetDeduct(betAmount));
      setIsBetting(true);
      setStatus("on");
      onPlaceBet(betAmount, autoCashoutMultiplier);
    }
  };
  const renderActionButton = () => {
    // Case 1: Game is waiting and player hasn't placed a bet
    if (
      (gamePhase === "waiting" ||
        gamePhase === "running" ||
        gamePhase === "crashed") &&
      !isBetting &&
      !isQueued
    ) {
      return (
        <PlaceBetButton
          onClick={handlePlaceBet}
          disabled={betAmount <= 0 || betAmount > balance}
        >
          Place Bet
        </PlaceBetButton>
      );
    }

    // Case 2: Game is running and player has an active bet
    if (gamePhase === "running" && isBetting) {
      return (
        <CashoutButton onClick={handleCashout} isBetting={true}>
          Cashout ${(betAmount * currentMultiplier).toFixed(2)}
        </CashoutButton>
      );
    }

    // Case 3: Game is waiting and player has placed a bet
    if (
      (gamePhase === "waiting" || gamePhase === "running") &&
      (isBetting || isQueued)
    ) {
      return (
        <CancelBetButton onClick={handleCancelBet}>Cancel Bet</CancelBetButton>
      );
    }

    // Case 4: Game is running but player is queued for next round
    // if (gamePhase === "running" && isQueued) {
    //   return <QueuedButton disabled>Queued for Next Round</QueuedButton>;
    // }

    // // Case 5: Game has crashed
    // if (gamePhase === "crashed") {
    //   return <WaitingButton disabled>Waiting for Next Round</WaitingButton>;
    // }
  };

  const handleCashout = () => {
    if (isBetting && gamePhase === "running") {
      const winnings = betAmount * currentMultiplier;
      setBalance(balance + winnings);
      dispatch(onCashoutIncrease(winnings));
      setIsBetting(false);
      setStatus("cashedOut");
      onCashout();
    }
  };
  const handleCancelBet = () => {
    setSnackbar({
      show: true,
      message: "Bet Cancelled!!",
      type: "error",
    });
    if (isBetting && (gamePhase === "waiting" || gamePhase === "running")) {
      setBalance(balance + betAmount);
      setIsBetting(false);
      setStatus("cancelled");
    }
  };
  return (
    <CardContainer>
      {snackbar.show && (
        <Snackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar({ ...snackbar, show: false })}
        />
      )}
      {/* <CardHeader>
        <BetNumber>Bet {betNumber}</BetNumber>
        <StatusIndicator status={status}>{status}</StatusIndicator>
      </CardHeader>
      <BalanceRow>
        <BalanceLabel>Balance:</BalanceLabel>
        <BalanceAmount>${balance.toFixed(2)}</BalanceAmount>
      </BalanceRow> */}
      {gamePhase === "running" && status === "queued" && (
        <WaitingInfo>
          <WaitingSpinner />
          Waiting for next round...
        </WaitingInfo>
      )}
      {gamePhase === "waiting" && isBetting && (
        <WaitingInfo>
          <WaitingSpinner />
          Prepare for the next round!
        </WaitingInfo>
      )}
      <AutoCashoutSwitch>
        <input
          type="checkbox"
          checked={autoCashout}
          onChange={(e) => setAutoCashout(e.target.checked)}
          disabled={gamePhase === "waiting"}
        />
        <span>Auto-Cashout</span>
      </AutoCashoutSwitch>
      <InputRow>
        <InputGroup>
          {/* <Label>Bet Amount</Label> */}
          <AmountInputGroup>
            <MinusButton onClick={decrementBetAmount}>-</MinusButton>
            {/* <InputContainer> */}

            <Input
              type="text"
              value={betAmount}
              onChange={handleBetAmountChange}
              min={10}
              // disabled={}
            />

            <PlusButton onClick={incrementBetAmount}>+</PlusButton>

            {/* </InputContainer> */}
          </AmountInputGroup>
        </InputGroup>
        {autoCashout && (
          <InputGroup>
            {/* <Label>Auto Cashout</Label> */}
            <Input
              type="number"
              value={autoCashoutMultiplier}
              onChange={handleAutoCashoutChange}
              disabled={gamePhase !== "waiting"}
            />
          </InputGroup>
        )}
      </InputRow>

      <ButtonGroup>
        {renderActionButton()}
        {/* Place Bet Button */}
      </ButtonGroup>
    </CardContainer>
  );
};

// Styling for the buttons and input
const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #16213e;
  color: #ffffff;
  text-align: center;
`;
const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
`;
const AmountInputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const StyledInput = styled.input`
  width: 100%;
  padding: 10px 40px; /* Adds space for the buttons */
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  text-align: center;
  background-color: #ffffff;
  color: #000;

  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;
const AmountButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border: none;
  background-color: #4ecca3;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const MinusButton = styled(AmountButton)`
  left: 5px;
`;

const PlusButton = styled(AmountButton)`
  right: 5px;
`;
// const Button = styled.button`
//   width: 40px;
//   padding: 8px;
//   background-color: #4ecca3;
//   border: none;
//   border-radius: 5px;
//   color: #ffffff;
//   cursor: pointer;

//   &:hover {
//     background-color: #3da682;
//   }
// `;
const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(78, 204, 163, 0.1);
  border-radius: 10px;
`;
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #4ecca3; }
  50% { box-shadow: 0 0 20px #4ecca3; }
  100% { box-shadow: 0 0 5px #4ecca3; }
`;
const BalanceLabel = styled.span`
  font-size: 16px;
  color: #a0a0a0;
`;

const BalanceAmount = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #4ecca3;
`;
const WaitingInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 10px;
  font-size: 16px;
  color: #ffd700;
`;
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const WaitingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #ffd700;
  border-top: 2px solid transparent;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${spinAnimation} 1s linear infinite;
`;
const LiveBetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(30, 144, 255, 0.1);
  border-radius: 10px;
  animation: ${glowAnimation} 2s infinite;
`;
const AutoCashoutSwitch = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 3px; /* Slightly reduced margin */

  input {
    appearance: none;
    width: 28px; /* Smaller width */
    height: 16px; /* Smaller height */
    background-color: #16213e;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;

    &:before {
      content: "";
      position: absolute;
      width: 12px; /* Smaller circle */
      height: 12px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      background-color: #a0a0a0;
      transition: all 0.3s ease;
    }

    &:checked {
      background-color: #4ecca3;

      &:before {
        transform: translateX(12px); /* Adjusted for smaller size */
        background-color: #ffffff;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    margin-left: 6px; /* Closer spacing */
    font-size: 0.75rem; /* Smaller font size */
  }
`;
const CurrentMultiplier = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #1e90ff;
`;

const PotentialWin = styled.span`
  font-size: 18px;
  color: #4ecca3;
`;
const CardContainer = styled.div`
  background: linear-gradient(145deg, #1e2a4a, #2a3f5f);
  width: 100%;
  border-radius: 15px;
  padding: 20px;
  color: #ffffff;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const BetNumber = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Balance = styled.div`
  font-size: 1.2rem;
  color: #4ecca3;
`;

const InputGroup = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

// const Input = styled.input`
//   width: 100%;
//   padding: 8px;
//   border: none;
//   border-radius: 5px;
//   background-color: #16213e;
//   color: #ffffff;
// `;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
const StatusIndicator = styled.span<{ status: string }>`
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${({ status }) =>
    status === "pending"
      ? "#ffd700"
      : status === "Placed"
      ? "#4ecca3"
      : status === "Won"
      ? "#4ecca3"
      : status === "crashed"
      ? "#ff6b6b"
      : status === "cashedOut"
      ? "#1e90ff"
      : "#ff6b6b"};
  color: ${({ status }) =>
    status === "pending" || status === "cashedOut" ? "#1a1a2e" : "#ffffff"};
`;
const PlaceBetButton = styled(Button)`
  background-color: #4ecca3;
  width: 100%;
  color: #1a1a2e;
  margin: 2px;
  &:hover:not(:disabled) {
    background-color: #45b393;
  }
`;
const CancelBetButton = styled(Button)`
  background-color: #ff4e4e;
  color: #1a1a2e;
  width: 100%;
  color: #ffffff;
  margin: 2px;
  &:hover:not(:disabled) {
    background-color: #ff0000;
  }
`;
const CashoutButton = styled(Button)<{ isBetting: boolean }>`
  background-color: #ffd700; /* Gold or default red */
  color: #1a1a2e;
  width: 100%;
  color: #ffffff;
  margin: 2px;
  &:hover:not(:disabled) {
    background-color: #ffd700;
  }
`;
const QueuedButton = styled(Button)`
  background-color: #f0f0f0;
  color: #666;
`;

const WaitingButton = styled(Button)`
  background-color: #666;
  color: white;
`;
const Status = styled.div`
  text-align: center;
  font-weight: bold;
`;

export default DemoBetCard;
