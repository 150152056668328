import React from "react";
import { MultiplierDisplay } from "./MultiplierDisplay";
import { BetPlacement } from "./BetPlacement";
import { GenericContainer } from "./container";
import { RecentWinners } from "./Winner";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch } from "../hooks";
import { Leaderboard } from "./LeaderBoard";

const Game: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="game-container">
      <GenericContainer title="Multiplier">
        <Row className="d-flex flex-column flex-md-row">
          {/* Leaderboard: visible only on medium and larger screens */}
          <Col
            xs={12}
            md={3}
            className="leaderboard mt-4 mt-md-0 d-none d-md-block"
          >
            <Leaderboard /> {/* Add the leaderboard */}
          </Col>

          {/* Left Column for Multiplier and Bet Placement */}
          <Col xs={12} md={6} className="multiplier-bet-section">
            <MultiplierDisplay />
            <BetPlacement />
          </Col>

          {/* Right Column for Recent Winners */}
          <Col xs={12} md={3} className="recent-winners mt-4 mt-md-0">
            <RecentWinners />
          </Col>
        </Row>
      </GenericContainer>
    </div>
  );
};

export { Game };
