import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DemoState {
  demoWallet: number;
}

const initialState: DemoState = {
  demoWallet: 1000,
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    onBetDeduct(state, action: PayloadAction<number>) {
      state.demoWallet -= action.payload;
    },
    onCashoutIncrease(state, action: PayloadAction<number>) {
      state.demoWallet += action.payload;
    },
  },
});

export const { onBetDeduct, onCashoutIncrease } = demoSlice.actions;
export default demoSlice.reducer;
