export const BASE_CONFIG = {
  API: {
    TIMEOUT: 10000,
    RETRY_ATTEMPTS: 3,
  },
  UI: {
    ITEMS_PER_PAGE: 10,
    DEFAULT_LANGUAGE: "en",
    CURRENCY: "KES",
    MIN_BET_AMOUNT: 10,
    MAX_BET_AMOUNT: 500,
  },
  FEATURES: {
    ENABLE_DARK_MODE: true,
  },
};

export const DEV_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "development",
  API: {
    ...BASE_CONFIG.API,
    BASE_URL: "https://node.codeshop.co.ke/",
    // BASE_URL: "http://localhost:8080/",
  },

  FEATURES: {
    ...BASE_CONFIG.FEATURES,
    ENABLE_BETA_FEATURES: true,
  },
};

export const STAGING_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "staging",
  API: {
    ...BASE_CONFIG.API,
    // BASE_URL: "http://localhost:8080/",
    BASE_URL: "https://node.codeshop.co.ke/",
  },

  FEATURES: {
    ...BASE_CONFIG.FEATURES,
    ENABLE_ANALYTICS: true,
  },
};
export const PROD_CONFIG = {
  ...BASE_CONFIG,
  ENVIRONMENT: "production",
  API: {
    ...BASE_CONFIG.API,
    // BASE_URL: "http://localhost:8080/",
    BASE_URL: "https://node.codeshop.co.ke/",
  },
  FEATURES: {
    ENABLE_DARK_MODE: true,
    ENABLE_ANALYTICS: true,
  },
};
