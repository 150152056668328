import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import DemoMultiplierDisplay from "./demo-multiplier";
import DemoBetCard from "./demo-bet-cards";
import { NextRoundOverlay } from "../components/NextRound";
import { RecentWinners } from "../components/Winner";
import { Leaderboard } from "../components/LeaderBoard";
import { CashoutNotification } from "../components/CashoutNotification";
import { Snackbar } from "../components/SnackBar";
import { SnackBarMode } from "../types";

const CrashGameDemo: React.FC = () => {
  const [gamePhase, setGamePhase] = useState<"waiting" | "running" | "crashed">(
    "waiting"
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [countdown, setCountdown] = useState(5);
  const [showNotification, setShowNotification] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  const startNewRound = useCallback(() => {
    setGamePhase("running");
    setCurrentMultiplier(1);
  }, []);

  const handleGameCrash = useCallback((crashPoint: number) => {
    setGamePhase("crashed");
    setCurrentMultiplier(crashPoint);
    setTimeout(() => {
      setGamePhase("waiting");
      setCountdown(5);
    }, 3000);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (gamePhase === "running") {
      intervalId = setInterval(() => {
        setCurrentMultiplier((prevMultiplier) => {
          const increase = 0.01 * prevMultiplier;
          return parseFloat((prevMultiplier + increase).toFixed(2));
        });

        if (Math.random() < 0.01) {
          handleGameCrash(currentMultiplier);
        }
      }, 100);
    } else if (gamePhase === "waiting" && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (gamePhase === "waiting" && countdown === 0) {
      startNewRound();
      setSnackbar({
        show: true,
        message: "Bet Closed!!",
        type: "success",
      });
    }

    return () => clearInterval(intervalId);
  }, [gamePhase, countdown, startNewRound, handleGameCrash, currentMultiplier]);

  const handlePlaceBet = useCallback((amount: number, autoCashout: number) => {
    console.log(`Bet placed: ${amount} with auto cashout at ${autoCashout}x`);
  }, []);

  const handleCashout = useCallback(() => {
    setShowNotification(true);
    console.log("Manual cashout");
  }, []);
  const handleNotificationClose = useCallback(() => {
    setShowNotification(false);
  }, []);

  const handleCountdownComplete = () => {
    setShowOverlay(false);
  };

  return (
    <GameContainer>
      {snackbar.show && (
        <Snackbar
          key={snackbar.message}
          message={snackbar.message}
          type={snackbar.type}
          onClose={() =>
            setSnackbar({ show: false, message: "", type: "success" })
          }
        />
      )}
      <CashoutNotification
        isVisible={showNotification}
        onClose={handleNotificationClose}
        message="You Won!Cashed out successfully!"
      />
      <SidebarContent>
        <Leaderboard /> {/* Add the leaderboard here */}
      </SidebarContent>
      <GameContent>
        <MultiplierDisplayContainer>
          <DemoMultiplierDisplay
            isGameRunning={gamePhase === "running"}
            currentMultiplier={currentMultiplier}
            onGameCrash={handleGameCrash}
          />
          {gamePhase === "waiting" && (
            <NextRoundOverlay
              isVisible={true}
              countdown={countdown}
              onCountdownComplete={handleCountdownComplete}
            />
          )}
        </MultiplierDisplayContainer>
        <BetCardsContainer>
          <DemoBetCard
            betNumber={1}
            initialBalance={1000}
            isGameRunning={gamePhase === "running"}
            currentMultiplier={currentMultiplier}
            onPlaceBet={handlePlaceBet}
            onCashout={handleCashout}
            gamePhase={gamePhase}
          />
          <DemoBetCard
            betNumber={2}
            initialBalance={1000}
            isGameRunning={gamePhase === "running"}
            currentMultiplier={currentMultiplier}
            onPlaceBet={handlePlaceBet}
            onCashout={handleCashout}
            gamePhase={gamePhase}
          />
        </BetCardsContainer>
      </GameContent>
      <SidebarContent>
        <RecentWinners />
      </SidebarContent>
    </GameContainer>
  );
};

// Styled components
const GameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  background-color: #0f1923;
  border-radius: 0px;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const GameContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const SidebarContent = styled.div`
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1024px) {
    max-width: none;
  }

  @media (max-width: 768px) {
    display: none; /* Hide on small screens */
  }
`;

const MultiplierDisplayContainer = styled.div`
  position: relative;
  height: 400px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const BetCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export default CrashGameDemo;
