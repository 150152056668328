import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {
  BetAttributes,
  BetWithUser,
  DashboardData,
  LoginAttributes,
  PaymentData,
  PaymentResponse,
  TransactionAttributes,
  TransactionsWithUser,
  UserAttributes,
  UserCreationAttributes,
} from "../types";
import { logout, setAccessToken, setUser } from "../store/slices";
import { RootState } from "../store";
import { DEV_CONFIG, STAGING_CONFIG } from "../app.config";

interface LoginResponse {
  user: UserAttributes;
  accessToken: string;
  refreshToken: string;
}
interface RefreshResponse {
  accessToken: string;
  user: UserAttributes;
}
console.log("BASE URL", DEV_CONFIG.API.BASE_URL);
const baseQuery = fetchBaseQuery({
  baseUrl: STAGING_CONFIG.API.BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).rootReducer.users.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error as FetchBaseQueryError).status === 401) {
    console.log("Token expired. Attempting to refresh...");

    // Attempting to get a new token
    const refreshResult = await baseQuery(
      { url: "/refresh-token", method: "POST" },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      console.log("Token refresh successful");
      const refreshResponse = refreshResult.data as RefreshResponse;

      // Store the new token
      api.dispatch(
        setAccessToken({
          accessToken: refreshResponse.accessToken,
          user: refreshResponse.user,
        })
      );

      // Retry the original query with the new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.log("Token refresh failed. Logging out.");
      api.dispatch(logout());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:8080" }),
  endpoints: (builder) => ({
    getUsers: builder.query<UserAttributes[], void>({
      query: () => "/users",
    }),
    getUserById: builder.query<UserAttributes, string>({
      query: (id) => `/users/${id}`,
    }),
    registerUser: builder.mutation<UserAttributes, UserCreationAttributes>({
      query: (newUser) => ({
        url: "/users/register",
        method: "POST",
        body: newUser,
      }),
    }),
    login: builder.mutation<LoginResponse, LoginAttributes>({
      query: (user) => ({
        url: "/users/login",
        method: "POST",
        body: user,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Clear user-related data and tokens on successful logout
          dispatch(logout());
          // dispatch(setAccessToken(null));  // Optionally clear the access token
          // dispatch(setUser(null));         // Optionally clear the user data
        } catch (error) {
          console.log("Failed to logout:", error);
        }
      },
    }),
    // user panel data
    getBetHistory: builder.query<BetAttributes[], string>({
      query: (id) => `/users/bet/${id}`,
    }),
    getTransactionHistory: builder.query<TransactionAttributes[], string>({
      query: (id) => `/users/transaction/${id}`,
    }),
    getAllBets: builder.query<BetWithUser[], void>({
      query: () => "/bets/all",
    }),
    deposit: builder.mutation<PaymentResponse, PaymentData>({
      query: (transactionData) => ({
        url: "/deposit",
        method: "POST",
        body: transactionData,
      }),
    }),
    withdraw: builder.mutation<PaymentResponse, PaymentData>({
      query: (withdrawData) => ({
        url: "/withdraw",
        method: "POST",
        body: withdrawData,
      }),
    }),
    refreshAccessToken: builder.mutation<RefreshResponse, void>({
      query: () => ({
        url: "/refresh-token",
        method: "POST",
      }),
    }),
    getTransactions: builder.query<TransactionsWithUser[], void>({
      query: () => ({
        url: "/get-transactions",
      }),
    }),
    getDashboardData: builder.query<DashboardData, void>({
      query: () => "/dashboard-data",
    }),
  }),
});

export const {
  useLogoutMutation,
  useGetDashboardDataQuery,
  useGetTransactionsQuery,
  useRefreshAccessTokenMutation,
  useGetAllBetsQuery,
  useGetBetHistoryQuery,
  useGetTransactionHistoryQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useRegisterUserMutation,
  useLoginMutation,
  useDepositMutation,
  useWithdrawMutation,
} = apiSlice;
