import React, { useState, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { useGetAllBetsQuery } from "../api";
import { useAppSelector } from "../hooks";
import { selectUser } from "../store/slices";
import { AlertTriangle } from "lucide-react";
import { formatDateToReadable } from "../utils";
import { BASE_CONFIG } from "../app.config";

type FilterType = "all" | "myBets" | "highest";

const RecentWinners: React.FC = () => {
  const user = useAppSelector(selectUser);
  const [filter, setFilter] = useState<FilterType>("all");
  const { data: betHistory, isLoading, error } = useGetAllBetsQuery();

  const filteredWinners = useMemo(() => {
    if (!betHistory || betHistory.length === 0) return [];

    const winners = betHistory
      .filter((bet) => bet.user !== null) // Filter out bets with null users
      .map((bet) => ({
        id: bet.id,
        userId: bet.userId,
        username: bet.user.username,
        betAmount: bet.amount,
        cashoutMultiplier: bet.crashMultiplier,
        winAmount: bet.amount * bet.crashMultiplier,
        timestamp: bet.createdAt,
      }));

    switch (filter) {
      case "myBets":
        return user
          ? winners.filter((winner) => winner.userId === user.id.toString())
          : [];
      case "highest":
        return [...winners].sort((a, b) => b.winAmount - a.winAmount);
      default:
        return winners;
    }
  }, [filter, betHistory, user]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingText>Loading recent winners...</LoadingText>
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <AlertTriangle size={48} color="#ff6b6b" />
        <ErrorText>Oops! We couldn't load the recent winners.</ErrorText>
        <ErrorSubText>
          Please try again later or contact support if the problem persists.
        </ErrorSubText>
      </ErrorContainer>
    );
  }

  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";
  return (
    <WinnersContainer>
      <Title>Recent Winners 🏆</Title>
      <FilterContainer>
        <FilterButton
          active={filter === "all"}
          onClick={() => setFilter("all")}
        >
          All
        </FilterButton>
        <FilterButton
          active={filter === "myBets"}
          onClick={() => setFilter("myBets")}
          disabled={!user}
        >
          My Bets
        </FilterButton>
        <FilterButton
          active={filter === "highest"}
          onClick={() => setFilter("highest")}
        >
          Top
        </FilterButton>
      </FilterContainer>
      {filteredWinners.length === 0 ? (
        <NoWinnersMessage>No winners to display</NoWinnersMessage>
      ) : (
        <WinnersList>
          {filteredWinners.map((winner) => (
            <WinnerCard
              key={winner.id}
              isCurrentUser={user ? winner.userId === user.id : false}
            >
              <Username>{winner.username}</Username>
              <WinInfo>
                <BetAmount>
                  {currencyCode}
                  {winner.betAmount.toFixed(2)}
                </BetAmount>
                <Multiplier>x{winner.cashoutMultiplier.toFixed(2)}</Multiplier>
              </WinInfo>
              <WinAmount>
                {currencyCode} {winner.winAmount.toFixed(2)}
              </WinAmount>
              {/* <Timestamp>
                {formatDateToReadable(new Date(winner.timestamp))}
              </Timestamp> */}
            </WinnerCard>
          ))}
        </WinnersList>
      )}
    </WinnersContainer>
  );
};

// ... (rest of the styled components remain the same)
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #4ecca3;
  border-top: 4px solid #1a1a2e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px;
`;

const LoadingText = styled.p`
  font-size: 18px;
  color: #4ecca3;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
`;

const ErrorText = styled.h3`
  font-size: 24px;
  color: #ff6b6b;
  margin: 20px 0 10px;
`;

const ErrorSubText = styled.p`
  font-size: 16px;
  color: #a0a0a0;
`;

const WinnersContainer = styled.div`
  height: 100%;
  background-color: #1a1a2e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffd700;
`;

// const FilterContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: stretch;
//   }
// `;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2c3e50;
  border-radius: 25px; /* Rounded corners for pill-like shape */
  padding: 5px;
  max-width: 300px;
  margin: 20px auto; /* Centered and adds spacing */
`;

const FilterButton = styled.button<{ active: boolean }>`
  flex: 1;
  background-color: ${(props) => (props.active ? "#4ecca3" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#4ecca3")};
  border: none;
  padding: 10px 15px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */

  &:hover {
    background-color: ${(props) => (props.active ? "#4ecca3" : "#d1e3df")};
  }

  &:disabled {
    background-color: #cccccc;
    color: #999999;
    cursor: not-allowed;
  }
`;

const WinnersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduce gap for compact design */
  max-height: 665px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #16213e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4ecca3;
    border-radius: 4px;
  }
`;

const WinnerCard = styled.div<{ isCurrentUser: boolean }>`
  background-color: ${(props) => (props.isCurrentUser ? "#2a3f5f" : "#16213e")};
  border-radius: 25px; /* Make the card more pill-like */
  padding: 5px 10px; /* Smaller padding for compactness */
  display: flex; /* Change to flex for horizontal layout */
  align-items: center; /* Center align items vertically */
  justify-content: space-between; /* Space items evenly */
  transition: transform 0.2s;
  width: auto; /* Allow it to take minimal space */
  margin: 5px 0; /* Add vertical margin between cards */

  &:hover {
    transform: scale(1.02);
  }
`;

const Username = styled.div`
  font-weight: bold;
  color: #4ecca3;
  font-size: 14px; /* Smaller font size */
`;

const WinInfo = styled.div`
  display: flex;
  align-items: center; /* Center align items vertically */
`;

const BetAmount = styled.span`
  color: #e0e0e0;
  font-size: 12px; /* Smaller font size */
  margin-right: 5px; /* Space between amount and multiplier */
`;

const Multiplier = styled.span`
  color: #ff6b6b;
  font-weight: bold;
  font-size: 12px; /* Smaller font size */
`;

const WinAmount = styled.div`
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  color: #4ecca3;
  margin-right: 5px; /* Space between win amount and timestamp */
`;

const Timestamp = styled.div`
  font-size: 10px; /* Smaller font size for timestamp */
  color: #a0a0a0;
`;

const NoWinnersMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #e0e0e0;
  margin-top: 20px;
`;

export { RecentWinners };
