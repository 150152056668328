// Login.tsx
import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLoginMutation } from "../api";
import { useAppDispatch } from "../hooks";
import { setUser } from "../store/slices";
import { initializeSocket } from "../store/websockets-actions";
// import { initializeSocket } from "../store/websockets-actions";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  width: 100%;
`;

const LoginButton = styled.button`
  background: #4ecca3;
  color: #16213e;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
`;

const Login: React.FC<{ handleSwitch: () => void }> = ({ handleSwitch }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    // login logic here
    try {
      const data = await login({ phone_number: phone, password }).unwrap();
      console.log("User logged in successfully:", data);
      handleSwitch();
      const dispatchData = {
        user: data.user,
        token: data.accessToken,
        isAuthenticated: true,
      };
      dispatch(setUser(dispatchData));
      // Initialize socket connection with the user ID
      initializeSocket(data.user.id);
      //   const socket = initializeSocket(data.user.id);

      // You can add logic to navigate to another page or show a success message here
    } catch (error) {
      console.log("Login failed:", error);
      console.log(error);
      alert("Login failed. Please try again.");
    }

    console.log("Login:", username, password);
  };

  return (
    <LoginContainer>
      {/* <LoginInput
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      /> */}
      <PhoneInput
        country={"ke"} // Set the default country
        value={phone}
        onChange={setPhone}
        containerStyle={{ marginBottom: "1rem" }}
        inputStyle={{
          width: "100%",
          padding: "0.5rem",
          borderRadius: "4px",
          border: "none",
        }}
      />
      <LoginInput
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <LoginButton onClick={handleLogin} disabled={isLoading}>
        {isLoading ? "Logging in..." : "Login"}
      </LoginButton>
      <p>
        Don't have an account?{" "}
        <span
          onClick={handleSwitch}
          style={{ cursor: "pointer", color: "#4ecca3" }}
        >
          Register
        </span>
      </p>
    </LoginContainer>
  );
};

export { Login };
