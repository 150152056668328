import React, { useState } from "react";
import styled from "styled-components";
import placeholder from "../assets/images/user.png";
import {
  Tabs,
  Tab,
  Card,
  Button,
  Form,
  Table,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaUser, FaWallet, FaHistory, FaExchangeAlt } from "react-icons/fa";
import {
  useDepositMutation,
  useGetBetHistoryQuery,
  useGetTransactionHistoryQuery,
  useWithdrawMutation,
} from "../api";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectUser } from "../store/slices";
import { BetAttributes, SnackBarMode } from "../types";
import { formatDateToReadable } from "../utils";
import { TransactionModal } from "../components/TransactionModal";
import { updateUserWallet } from "../store/slices/user-slice";
import { LoadingDots } from "../components/LoadingDots";
import { Snackbar } from "../components/SnackBar";
import { BASE_CONFIG } from "../app.config";

// Styled components
const StyledCard = styled(Card)`
  max-width: 900px;
  margin: 2rem auto;
  @media (max-width: 768px) {
    max-width: 95%; /* Full width on smaller screens */
  }
`;

const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
`;

const BalanceText = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
`;

const StyledTable = styled(Table)`
  @media (max-width: 576px) {
    font-size: 0.9rem; /* Smaller font size on extra small screens */
  }
`;

export const UserPanel: React.FC = () => {
  const user = useAppSelector(selectUser)!;
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [deposit, { isLoading: depositLoading }] = useDepositMutation();
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  const [withdraw, { isLoading: withdrawalLoading }] = useWithdrawMutation();
  const dispatch = useAppDispatch();
  const { data: betHistory, isLoading: betHistoryLoading } =
    useGetBetHistoryQuery(user.id);
  const { data: transactionHistory, isLoading: transactionHistoryLoading } =
    useGetTransactionHistoryQuery(user.id);

  const handleDeposit = async ({
    amount,
    mpesaNumber,
  }: {
    amount: number;
    mpesaNumber: string;
  }) => {
    try {
      const response = await deposit({
        amount,
        mpesa_number: mpesaNumber,
        userId: user.id.toString(),
      });

      if ("data" in response && response.data !== undefined) {
        dispatch(updateUserWallet(response.data.wallet_balance));
        setSnackbar({
          show: true,
          message: response.data.message,
          type: "success",
        });
      }
      if ("error" in response) {
        setSnackbar({ show: true, message: "Payment failed!", type: "error" });
      }
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Error during deposit!",
        type: "error",
      });
    }
  };

  const handleWithdraw = async ({
    amount,
    mpesaNumber,
  }: {
    amount: number;
    mpesaNumber: string;
  }) => {
    try {
      const response = await withdraw({
        amount,
        mpesa_number: mpesaNumber,
        userId: user.id,
      });

      if ("data" in response && response.data !== undefined) {
        dispatch(updateUserWallet(response.data.wallet_balance));
        setSnackbar({
          show: true,
          message: response.data.message,
          type: "success",
        });
      }
      if ("error" in response) {
        setSnackbar({
          show: true,
          message: "Withdrawal failed!",
          type: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Error during withdrawal!",
        type: "error",
      });
    }
  };

  if (depositLoading || withdrawalLoading) {
    return <LoadingDots />;
  }
  if (betHistoryLoading || transactionHistoryLoading) {
    return <Spinner animation="border" />;
  }

  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";

  return (
    <StyledCard>
      {snackbar.show && (
        <Snackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar({ ...snackbar, show: false })}
        />
      )}

      <Card.Header>
        <h2>User Dashboard</h2>
      </Card.Header>
      <Card.Body>
        <Tabs defaultActiveKey="profile" id="user-panel-tabs" className="mb-3">
          <Tab
            eventKey="profile"
            title={
              <>
                <FaUser /> Profile
              </>
            }
          >
            <Card>
              <Card.Body>
                <div className="d-flex align-items-center mb-4 flex-column flex-sm-row">
                  <AvatarImage
                    src={placeholder}
                    alt="User avatar"
                    className="me-3 mb-3 mb-sm-0"
                  />
                  <div className="text-center text-sm-start">
                    <h3>Username</h3>
                    <p className="text-muted">{user.username}</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="wallet"
            title={
              <>
                <FaWallet /> Wallet
              </>
            }
          >
            <Card>
              <Card.Body className="text-center">
                <h3>Current Balance</h3>
                <BalanceText>
                  {currencyCode}
                  {user.wallet_balance.toFixed(2)}
                </BalanceText>
                <div className="mt-4">
                  <Button
                    variant="success"
                    className="me-2"
                    onClick={() => setShowDepositModal(true)}
                  >
                    Deposit
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowWithdrawModal(true)}
                  >
                    Withdraw
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="betHistory"
            title={
              <>
                <FaHistory /> Bet History
              </>
            }
          >
            <StyledTable striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Multiplier</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {betHistory === undefined || betHistory.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  betHistory.map((bet) => (
                    <tr key={bet.id}>
                      <td>{formatDateToReadable(new Date(bet.createdAt))}</td>
                      <td>{bet.status}</td>
                      <td>x{bet.crashMultiplier}</td>
                      <td
                        className={
                          bet.status === "cashedOut"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {currencyCode}
                        {bet.amount}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </StyledTable>
          </Tab>

          <Tab
            eventKey="transactionHistory"
            title={
              <>
                <FaExchangeAlt /> Transactions
              </>
            }
          >
            <StyledTable striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactionHistory === undefined ||
                transactionHistory.length === 0 ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No records found
                    </td>
                  </tr>
                ) : (
                  transactionHistory.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>
                        {formatDateToReadable(
                          new Date(transaction.created_at!)
                        )}
                      </td>
                      <td>{transaction.status}</td>
                      <td>{transaction.transaction_type}</td>
                      <td
                        className={
                          transaction.transaction_type === "credit"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {currencyCode}
                        {transaction.amount}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </StyledTable>
          </Tab>
        </Tabs>
      </Card.Body>
      <TransactionModal
        show={showDepositModal}
        handleClose={() => setShowDepositModal(false)}
        transactionType="deposit"
        onSubmit={handleDeposit}
      />

      {/* Withdraw Modal */}
      <TransactionModal
        show={showWithdrawModal}
        handleClose={() => setShowWithdrawModal(false)}
        transactionType="withdraw"
        onSubmit={handleWithdraw}
      />
    </StyledCard>
  );
};
