import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components";
// import { useSocket } from "../hooks/SocketProvider";
// import { current } from "@reduxjs/toolkit";
import { useSocket } from "../store/websockets-actions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectUser, updateUserWallet } from "../store/slices/user-slice";
import { BASE_CONFIG } from "../app.config";
import { SnackBarMode } from "../types";
import { Snackbar } from "./SnackBar";
import { CashoutNotification } from "./CashoutNotification";
// import { getSocket } from "../store/websockets-actions";
// import socket from "../store/websockets-actions";

interface BetCardProps {
  betRefId: string;
  betNumber: number;
  onPlaceBet: (bet: { amount: number; multiplier: number }) => void;
  initialBalance: number;
}

const BetCard: React.FC<BetCardProps> = ({
  betNumber,
  betRefId,
  onPlaceBet,
  initialBalance,
}) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number>(10);
  const [multiplier, setMultiplier] = useState<number>(1.5);
  const [autoCashout, setAutoCashout] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("Pending");
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(1);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [potentialWin, setPotentialWin] = useState<number>(0);
  const [showNotification, setShowNotification] = useState(false);
  const [betId, setBetId] = useState<string>();
  const betIdRef = useRef<string | undefined>();
  const animationRef = useRef<number>();
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  const { socket, isConnected, placeBet, cancelBet, cashOut } = useSocket();

  useEffect(() => {
    if (user) {
      setBalance(user.wallet_balance);
    }
  }, [user]);
  useEffect(() => {
    const socketInstance = socket();
    console.log("isConnected:😂😴", isConnected);
    if (isConnected) {
      console.log("Socket is connected:", socketInstance.connected);
    } else {
      console.error("Socket connection failed.");
    }
  }, [isConnected, socket]);

  const handleAmountChange = (change: number) => {
    setAmount((prevAmount) => Math.max(0, prevAmount + change));
  };
  const handleNotificationClose = useCallback(() => {
    setShowNotification(false);
  }, []);
  const handleCashout = useCallback(() => {
    setShowNotification(true);
  }, []);
  const handlePlaceBet = useCallback(() => {
    if (amount > 0 && amount <= balance) {
      onPlaceBet({ amount, multiplier });
      setStatus("Placed");
      setIsAnimating(true);
      setBalance((prevBalance) => prevBalance - amount);
      setIsWaiting(true);
      console.log("isConnected:😂😴‼", isConnected);
      if (isConnected) {
        placeBet({
          betAmount: amount,
          betRefId: betRefId,
          autoCashOut: autoCashout,
          autoCashOutValue: autoCashout ? multiplier : null,
        });
        console.log("Emitting placeBet:", {
          betAmount: amount,
          betRefId: betRefId,
          autoCashOut: autoCashout,
          autoCashOutValue: autoCashout ? multiplier : null,
        });
        setBetId(betRefId);
        console.log("betId", betId);
      }
    } else if (amount > balance) {
      setStatus("Insufficient Funds");
      setTimeout(() => setStatus("Pending"), 2000);
    } else {
      setStatus("Error");
      setTimeout(() => setStatus("Pending"), 2000);
    }
  }, [
    amount,
    balance,
    onPlaceBet,
    multiplier,
    isConnected,
    socket,
    betRefId,
    autoCashout,
    betId,
  ]);
  const logSocketEvent = (eventName: string, data: any) => {
    console.log(`Socket event received: ${eventName}`, data);
  };
  const handleCancelBet = () => {
    // setSnackbar({
    //   show: true,
    //   message: "Bet Cancelled!!",
    //   type: "error",
    // });
    if (isWaiting || isPreparing) {
      // TODO:  send a SOCKET to the serve to handle bet cancel
      const socketInstance = socket();
      if (!betId) return;
      cancelBet({ betId });
      // setBalance(balance + amount);
      // setIsPlaying(false);
      // setStatus("cancelled");
    }
  };
  useEffect(() => {
    betIdRef.current = betId; // Keep betIdRef updated with the latest betId
  }, [betId]);
  useEffect(() => {
    if (!isConnected) return;

    const socketInstance = socket();

    const handleBetCancled = (data: {
      dbBetId: string;
      status: string;
      refundedAmount: number;
      updatedWalletBalance: number;
    }) => {
      console.log(data.dbBetId);
      if (data.dbBetId === betId) {
        console.log(
          "Executed for this bet cancelled",
          data.dbBetId,
          data.status,
          data.updatedWalletBalance
        );
        dispatch(updateUserWallet(data.updatedWalletBalance));
        // setStatus(data.status);
        setBetId(data.dbBetId);
        setIsWaiting(false);
        setIsPreparing(false);
        setSnackbar({
          show: true,
          message: "Bet cancelled!!",
          type: "error",
        });
      }
    };
    const handleBetPlaced = (data: {
      dbBetId: string;
      success: boolean;
      status: string;
      betRefId: string;
      updatedUserWallet: number;
    }) => {
      console.log(data.betRefId, betId, data.dbBetId, data.updatedUserWallet);
      if (data.betRefId === betId) {
        console.log("Executed for this", data.dbBetId);
        dispatch(updateUserWallet(data.updatedUserWallet));
        setStatus(data.status);
        setBetId(data.dbBetId);
        setIsWaiting(true);
        setSnackbar({
          show: true,
          message: "Bet Placed!!",
          type: "success",
        });
      }
    };

    const handleGameStart = (data: { betId: string; status: string }) => {
      console.log("++++==+++++game start");
      if (data.betId === betId) {
        setIsWaiting(false);
        setIsPreparing(false);
        setStatus(data.status);
        setIsPlaying(true);
        setCurrentMultiplier(1);
      }
    };

    const handleGameInProgress = (data: { betId: string }) => {
      const currentBetId = betIdRef.current;
      console.log("preparation phase triggered", currentBetId);
      console.log(betId);
      if (data.betId === currentBetId) {
        console.log("game in progress", betId);
        setIsWaiting(true);
      }
    };

    const handleGameEnd = (data: {
      betId: string;
      crashMultiplier: number;
      status: string;
    }) => {
      console.log("GAME CRASHED", betId);
      if (data.betId === betId) {
        setIsPlaying(false);
        setStatus(data.status);
        setCurrentMultiplier(1);
        if (data.status === "crashed") {
          // setBalance((prevBalance) => prevBalance - amount);
        }
      }
    };

    const handlePreparationPhase = (data: { betId: string }) => {
      const currentBetId = betIdRef.current;
      console.log("preparationnnnn phase triggered", currentBetId);
      if (data.betId === currentBetId) {
        setIsPreparing(true);
        setIsWaiting(false);
      }
    };

    const handleGameCashoutSuccess = (data: {
      betId: string;
      winnings: number;
      currentMultiplier: number;
      status: string;
    }) => {
      handleCashout();
      if (data.betId === betId) {
        setBalance((prevBal) => prevBal + data.winnings);
        setIsPlaying(false);
        setStatus(data.status);
      }
    };

    const handleGameTick = (data: {
      betId: string;
      currentMultiplier: number;
    }) => {
      if (data.betId === betId) {
        console.log(betId);
        setIsPlaying(true);
        setCurrentMultiplier(data.currentMultiplier);
      }
    };

    socketInstance.on("betPlaced", handleBetPlaced);
    socketInstance.on("betCancelled", handleBetCancled);
    socketInstance.on("gameStart", handleGameStart);
    socketInstance.on("gameInProgress", handleGameInProgress);
    socketInstance.on("gameEnd", handleGameEnd);
    socketInstance.on("preparationPhase", handlePreparationPhase);
    socketInstance.on("activeBetTick", handleGameTick);
    socketInstance.on("autoCashedOut", handleGameCashoutSuccess);

    return () => {
      socketInstance.off("betPlaced", handleBetPlaced);
      socketInstance.on("betCancelled", handleBetCancled);
      // socketInstance.off("gameStart", handleGameStart);
      // socketInstance.off("gameInProgress", handleGameInProgress);
      // socketInstance.off("gameEnd", handleGameEnd);
      // socketInstance.off("preparationPhase", handlePreparationPhase);
      // socketInstance.off("activeBetTick", handleGameTick);
      // socketInstance.off("autoCashedOut", handleGameCashoutSuccess);
    };
  }, [isConnected, socket, betId, betRefId, amount]);

  useEffect(() => {
    if (isPlaying) {
      const updatePotentialWin = () => {
        setPotentialWin(amount * currentMultiplier);
        animationRef.current = requestAnimationFrame(updatePotentialWin);
      };
      animationRef.current = requestAnimationFrame(updatePotentialWin);
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      setPotentialWin(0);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [amount, currentMultiplier, isPlaying]);

  const handleCashOut = useCallback(() => {
    if (!betId) return;
    const socketInstance = socket();
    if (isPlaying) {
      setStatus("Cashed Out");
      setIsPlaying(false);
      setBalance((prevBalance) => prevBalance + potentialWin);
      if (isConnected) {
        socketInstance.emit("cashOut", {
          betId: betId,
          currentMultiplier: currentMultiplier,
        });
      }
    }
  }, [betId, socket, isPlaying, isConnected, potentialWin, currentMultiplier]);
  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";
  return (
    <StyledBetCard>
      {snackbar.show && (
        <Snackbar
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar({ ...snackbar, show: false })}
        />
      )}
      <CashoutNotification
        isVisible={showNotification}
        onClose={handleNotificationClose}
        message="You Won!Cashed out successfully!"
      />
      {/* <CardHeader>
        <BetNumber>{betNumber}</BetNumber>
        <StatusIndicator status={status}>{status}</StatusIndicator>
      </CardHeader>

      <BalanceRow>
        <BalanceLabel>Balance:</BalanceLabel>
        <BalanceAmount>${balance.toFixed(2)}</BalanceAmount>
      </BalanceRow> */}

      {/* {isPlaying && (
        <LiveBetInfo>
          <CurrentMultiplier>{currentMultiplier.toFixed(2)}x</CurrentMultiplier>
          <PotentialWin>Potential Win: ${potentialWin.toFixed(2)}</PotentialWin>
        </LiveBetInfo>
      )} */}

      {isWaiting && (
        <WaitingInfo>
          <WaitingSpinner />
          Waiting for next round...
        </WaitingInfo>
      )}
      {isPreparing && (
        <WaitingInfo>
          <WaitingSpinner />
          Prepare for the next round!
        </WaitingInfo>
      )}
      {!isPlaying && !isPreparing && !isWaiting && (
        <>
          <AutoCashoutSwitch>
            <input
              type="checkbox"
              checked={autoCashout}
              onChange={(e) => setAutoCashout(e.target.checked)}
              disabled={isPlaying || isWaiting}
            />
            <span>Auto Cash Out</span>
          </AutoCashoutSwitch>
          <InputRow>
            <InputGroup>
              {/* <Label>Bet Amount</Label> */}
              <AmountInputGroup>
                <MinusButton onClick={() => handleAmountChange(-10)}>
                  -
                </MinusButton>
                <StyledInput
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                  max={balance}
                  // placeholder="Enter bet amount"
                  disabled={isPlaying || isWaiting}
                />
                <PlusButton onClick={() => handleAmountChange(10)}>
                  +
                </PlusButton>
                <CurrencyIndicator>{currencyCode}</CurrencyIndicator>
              </AmountInputGroup>
            </InputGroup>

            {autoCashout && (
              <InputGroup>
                {/* <Label>Auto-Cashout</Label> */}
                <AutoCashoutInputGroup>
                  <StyledInput
                    type="number"
                    value={multiplier}
                    onChange={(e) => setMultiplier(Number(e.target.value))}
                    min="1.01"
                    step="0.01"
                    placeholder="Multiplier"
                    disabled={isPlaying || isWaiting}
                  />

                  {autoCashout && isPlaying && (
                    <MultiplierIndicator>x</MultiplierIndicator>
                  )}
                </AutoCashoutInputGroup>
              </InputGroup>
            )}
          </InputRow>
        </>
      )}

      <ButtonGroup>
        {!isPlaying && !isWaiting && !isPreparing && (
          <PlaceBetButton
            onClick={handlePlaceBet}
            isAnimating={isAnimating}
            disabled={amount <= 0 || amount > balance || isPlaying || isWaiting}
          >
            Place Bet
          </PlaceBetButton>
        )}
        {isPlaying && (
          <CashOutButton
            onClick={handleCashOut}
            disabled={!isPlaying}
            isPlaying={isPlaying}
          >
            Cashout {isPlaying ? `$${potentialWin.toFixed(2)}` : ""}
          </CashOutButton>
        )}
        {(isWaiting || isPreparing) && (
          <CancelBetButton onClick={handleCancelBet}>
            Cancel Bet
          </CancelBetButton>
        )}
      </ButtonGroup>
    </StyledBetCard>
  );
};

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #4ecca3; }
  50% { box-shadow: 0 0 20px #4ecca3; }
  100% { box-shadow: 0 0 5px #4ecca3; }
`;

const StyledBetCard = styled.div`
  background: linear-gradient(145deg, #1e2a4a, #2a3f5f);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 1rem;
  color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;
const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const InputGroup = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const AmountInputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 40px; /* Adds space for the buttons */
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  text-align: center;
  background-color: #ffffff;
  color: #000;

  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

const AmountButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px; /* Reduced width */
  height: 20px; /* Reduced height */
  border: none;
  background-color: transparent; /* Removed background color */
  color: #4ecca3; /* Optional: Keep green text if you want */
  border-radius: 0; /* Removed border radius */
  cursor: pointer;
  font-size: 14px; /* Adjusted font size for smaller button */
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    color: #ccc; /* Disabled state color */
    cursor: not-allowed;
  }
`;

const MinusButton = styled(AmountButton)`
  left: 5px; /* Adjust position as needed */
`;

const PlusButton = styled(AmountButton)`
  right: 40px; /* Adjust position as needed */
`;

const CurrencyIndicator = styled.span`
  margin-left: 5px;
  font-size: 18px;
  color: #fff;
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BetNumber = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #4ecca3;
`;

const StatusIndicator = styled.span<{ status: string }>`
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${({ status }) =>
    status === "pending"
      ? "#ffd700"
      : status === "Placed"
      ? "#4ecca3"
      : status === "Won"
      ? "#4ecca3"
      : status === "crashed"
      ? "#ff6b6b"
      : status === "cashedOut"
      ? "#1e90ff"
      : "#ff6b6b"};
  color: ${({ status }) =>
    status === "pending" || status === "cashedOut" ? "#1a1a2e" : "#ffffff"};
`;

const BalanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(78, 204, 163, 0.1);
  border-radius: 10px;
`;

const BalanceLabel = styled.span`
  font-size: 16px;
  color: #a0a0a0;
`;

const BalanceAmount = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #4ecca3;
`;
// const InputGroup = styled.div`
//   flex: 1;
//   position: relative;
// `;

// const AmountInputGroup = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const AmountButton = styled.button`
//   background-color: #4ecca3;
//   color: #1a1a2e;
//   border: none;
//   border-radius: 5px;
//   font-size: 18px;
//   font-weight: bold;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   transition: all 0.3s ease;

//   &:hover {
//     background-color: #45b393;
//   }
// `;

const AutoCashoutInputGroup = styled.div`
  display: flex;
  align-items: center;
`;
const LiveBetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(30, 144, 255, 0.1);
  border-radius: 10px;
  animation: ${glowAnimation} 2s infinite;
`;
// const InputRow = styled.div`
//   display: flex;
//   justify-content: space-between;
//   gap: 20px;
//   margin-bottom: 20px;
// `;

const CurrentMultiplier = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #1e90ff;
`;

const PotentialWin = styled.span`
  font-size: 18px;
  color: #4ecca3;
`;

const WaitingInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 10px;
  font-size: 16px;
  color: #ffd700;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const WaitingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #ffd700;
  border-top: 2px solid transparent;
  border-radius: 50%;
  margin-right: 10px;
  animation: ${spinAnimation} 1s linear infinite;
`;

// const InputGroup = styled.div`
//   position: relative;
//   margin-bottom: 20px;
// `;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #a0a0a0;
`;
// const StyledInput = styled.input`
//   width: 100%;
//   padding: 10px 15px;
//   border: none;
//   border-radius: 5px;
//   background-color: #16213e;
//   color: #ffffff;
//   font-size: 16px;
//   transition: all 0.3s ease;

//   &:focus {
//     outline: none;
//     box-shadow: 0 0 0 2px #4ecca3;
//   }

//   &:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

// const CurrencyIndicator = styled.span`
//   position: absolute;
//   right: 10px;
//   top: 35px;
//   color: #4ecca3;
// `;

const MultiplierIndicator = styled(CurrencyIndicator)``;

const AutoCashoutSwitch = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 3px; /* Slightly reduced margin */

  input {
    appearance: none;
    width: 28px; /* Smaller width */
    height: 16px; /* Smaller height */
    background-color: #16213e;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;

    &:before {
      content: "";
      position: absolute;
      width: 12px; /* Smaller circle */
      height: 12px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      background-color: #a0a0a0;
      transition: all 0.3s ease;
    }

    &:checked {
      background-color: #4ecca3;

      &:before {
        transform: translateX(12px); /* Adjusted for smaller size */
        background-color: #ffffff;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    margin-left: 6px; /* Closer spacing */
    font-size: 0.75rem; /* Smaller font size */
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BaseButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PlaceBetButton = styled(BaseButton)<{ isAnimating: boolean }>`
  background-color: #4ecca3;
  color: #1a1a2e;
  width: 100%;
  flex-grow: 1;
  margin-right: 10px;

  &:hover:not(:disabled) {
    background-color: #45b393;
  }

  animation: ${({ isAnimating }) => (isAnimating ? glowAnimation : "none")} 1s
    ease-in-out;
`;
const CancelBetButton = styled(BaseButton)`
  background-color: #ff4e4e;
  color: #1a1a2e;
  width: 100%;
  color: #ffffff;
  margin: 2px;
  &:hover:not(:disabled) {
    background-color: #ff0000;
  }
`;
const CashOutButton = styled(BaseButton)<{ isPlaying: boolean }>`
  background-color: ${({ isPlaying }) =>
    isPlaying ? "#ffd700" : "#1e90ff"}; /* Gold or default red */
  color: #ffffff;
  flex-grow: 1;
  width: 100%;
  &:hover:not(:disabled) {
    background-color: #1c86ee;
  }
`;

export { BetCard };
