import React, { useRef } from "react";
import { BetCard } from "./BetCard";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const BetPlacement: React.FC = () => {
  // Generate unique betRefIds for each card
  const betRefId1 = "betRef1";
  const betRefId2 = "betRef2";
  const handlePlaceBet = (bet: { amount: number; multiplier: number }) => {
    console.log("Bet Placed:", bet);
    //TODO: Implement the logic to handle bet placement (e.g., sending to the server, updating state)
  };

  return (
    <div style={{ paddingTop: 12 }}>
      <Row>
        <Col>
          <BetCard
            betRefId={betRefId1}
            betNumber={1}
            onPlaceBet={handlePlaceBet}
            initialBalance={10}
          />
        </Col>
        <Col>
          <BetCard
            betRefId={betRefId2}
            betNumber={2}
            onPlaceBet={handlePlaceBet}
            initialBalance={100}
          />
        </Col>
      </Row>
    </div>
  );
};

export { BetPlacement };
