import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./hooks";
import { logout, selectIsAuthenticated, setAccessToken } from "./store/slices";
import { useLogoutMutation, useRefreshAccessTokenMutation } from "./api";
import { RootState } from "./store";
import { Header } from "./components/Header";
import { Game } from "./components/Game";
import { UserPanel } from "./screens/UserPanel";
import "./App.css";
import LoadingScreen from "./screens/LoadingScreen";
import CrashGameDemo from "./demo/demo-game";

function App() {
  const dispatch = useAppDispatch();
  const [logoutUser] = useLogoutMutation();
  const [refreshToken] = useRefreshAccessTokenMutation();
  const isAuthenticated = useAppSelector((state: RootState) =>
    selectIsAuthenticated(state)
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refreshTokenOnStartup = async () => {
      try {
        const result = await refreshToken().unwrap();
        dispatch(
          setAccessToken({ accessToken: result.accessToken, user: result.user })
        );
        console.log("Token refreshed on startup", result.accessToken);
      } catch (error) {
        console.log("Failed to refresh token on startup:", error);
        console.log("logged out");
        setIsLoading(false);
        await logoutUser().unwrap();
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAuthenticated) {
      refreshTokenOnStartup();
    } else {
      setIsLoading(false);
    }
  }, [dispatch, refreshToken, isAuthenticated]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          {/* Admin Routes with AdminLayout */}
          <Route
            path="/"
            element={
              isAuthenticated ? <Game /> : <Navigate to="/demo" replace />
            }
          />
          {/* <Route path="/" element={<Game />} /> */}
          <Route path="/demo" element={<CrashGameDemo />} />

          <Route
            path="/user-panel"
            element={
              isAuthenticated ? <UserPanel /> : <Navigate to="/" replace />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
