import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/auth-slice";
import usersReducer from "./slices/user-slice";
import demoReducer from "./slices/demo-slice";
import websocketReducer from "./slices/websocket-slice";

const rootReducer = combineReducers({
  demo: demoReducer,
  auth: authReducer,
  users: usersReducer,
  webSocket: websocketReducer,
});

export default rootReducer;
