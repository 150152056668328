import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";
import { motion, AnimatePresence } from "framer-motion";
import { FaWallet } from "react-icons/fa";
import { BASE_CONFIG } from "../app.config";
import { useAppDispatch, useAppSelector } from "../hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface DemoMultiplierDisplayProps {
  isGameRunning: boolean;
  currentMultiplier: number;
  onGameCrash: (crashPoint: number) => void;
}

const DemoMultiplierDisplay: React.FC<DemoMultiplierDisplayProps> = ({
  isGameRunning,
  currentMultiplier,
  onGameCrash,
}) => {
  const demoWallet = useAppSelector(
    (state) => state.rootReducer.demo.demoWallet
  );
  const [multiplierHistory, setMultiplierHistory] = useState<number[]>([]);
  const [isCrashed, setIsCrashed] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [maxMultiplier, setMaxMultiplier] = useState<number>(2);
  const [previousCrashes, setPreviousCrashes] = useState<number[]>([
    2.11, 2.55, 70.44, 30.44, 2.11, 2.55, 70.44,
  ]);
  const [walletBalance, setWalletBalance] = useState(demoWallet);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    if (isGameRunning) {
      setShowOverlay(false);
      setIsCrashed(false);
      setMultiplierHistory([1]);
      setMaxMultiplier(2);
    } else {
      setShowOverlay(true);
    }
  }, [isGameRunning]);

  useEffect(() => {
    if (isGameRunning) {
      const newMultiplier = parseFloat(currentMultiplier.toFixed(2));
      setMultiplierHistory((prev) => [...prev, newMultiplier]);

      if (newMultiplier > maxMultiplier) {
        setMaxMultiplier(Math.ceil(newMultiplier));
      }

      if (Math.random() < 0.01) {
        setIsCrashed(true);
        onGameCrash(newMultiplier);
      }

      animationRef.current = requestAnimationFrame(updateGraph);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isGameRunning, currentMultiplier, onGameCrash, maxMultiplier]);

  const updateGraph = () => {
    // This function would be called on each animation frame
    // You can add more dynamic updates here if needed
  };

  const data: ChartData<"line"> = {
    labels: multiplierHistory.map((_, index) => index.toString()),
    datasets: [
      {
        label: "Multiplier",
        data: multiplierHistory,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        pointRadius: 0,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: false,
        min: 1,
        max: maxMultiplier,
        ticks: {
          color: "rgba(255, 255, 255, 0.7)",
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    animation: {
      duration: 0,
    },
  };
  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";
  return (
    <DisplayContainer>
      <TopSection>
        <PreviousCrashesContainer>
          {previousCrashes.map((crash, index) => (
            <CrashBubble key={index} crash={crash}>
              {crash.toFixed(2)}x
            </CrashBubble>
          ))}
        </PreviousCrashesContainer>
        <WalletBalance>
          <FaWallet />
          {currencyCode}
          {demoWallet.toFixed(2)}
        </WalletBalance>
      </TopSection>
      <GraphContainer>
        <Line data={data} options={options} />
        <AnimatePresence>
          {isCrashed && (
            <CrashedOverlay
              key="crashed"
              initial={{ opacity: 0, scale: 1.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{ duration: 0.5 }}
            >
              <CrashedText>
                CRASHED AT {currentMultiplier.toFixed(2)}x
              </CrashedText>
            </CrashedOverlay>
          )}
        </AnimatePresence>
      </GraphContainer>
      <MultiplierContainer>
        <CurrentMultiplier $isCrashed={isCrashed}>
          {currentMultiplier.toFixed(2)}x
        </CurrentMultiplier>
      </MultiplierContainer>
    </DisplayContainer>
  );
};

const DisplayContainer = styled.div`
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  border-radius: 15px;
  padding: 20px;
  height: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 350px;
    padding: 15px;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PreviousCrashesContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const CrashBubble = styled.div<{ crash: number }>`
  background: ${(props) => (props.crash < 2 ? "#ff6b6b" : "#4ecca3")};
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
`;

const WalletBalance = styled.div`
  display: flex;
  align-items: center;
  background: #2c3e50;
  color: #ecf0f1;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: bold;

  svg {
    margin-right: 8px;
    color: #f1c40f;
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
`;

const GraphContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const CrashedOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 107, 107, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
`;

const CrashedText = styled.h2`
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const MultiplierContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  @media (max-width: 480px) {
    height: 50px;
  }
`;

interface CurrentMultiplierProps {
  $isCrashed: boolean;
}

const CurrentMultiplier = styled.div<CurrentMultiplierProps>`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${(props) => (props.$isCrashed ? "#ff6b6b" : "#4ecca3")};
  transition: color 0.3s ease;
  text-shadow: 0 0 10px rgba(78, 204, 163, 0.5);

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export default DemoMultiplierDisplay;
