import { useState, useEffect, useCallback } from "react";
import { io, Socket } from "socket.io-client";
import { DEV_CONFIG } from "../app.config";
import { useAppSelector } from "../hooks";

interface ServerToClientEvents {
  gameStart: (data: { betId: string; status: string }) => void;
  gameInProgress: (data: { betId: string }) => void;
  preparationPhase: (data: { betId: string }) => void;
  gameTick: (data: { betId: string; currentMultiplier: number }) => void;
  activeBetTick: (data: { betId: string; currentMultiplier: number }) => void;
  betPlaced: (data: {
    dbBetId: string;
    success: true;
    status: string;
    betRefId: string;
    updatedUserWallet: number;
  }) => void;
  betCancelled: (data: {
    dbBetId: string;
    status: string;
    refundedAmount: number;
    updatedWalletBalance: number;
  }) => void;
  globalGameStart: () => void;
  globalGameStop: () => void;
  globalPreparationPhase: () => void;
  gameEnd: (data: {
    betId: string;
    crashMultiplier: number;
    status: string;
  }) => void;
  autoCashedOut: (data: {
    betId: string;
    winnings: number;
    currentMultiplier: number;
    status: string;
  }) => void;
  cashOutSuccess: (data: {
    userId: string;
    winnings: number;
    currentMultiplier: number;
  }) => void;
}

interface ClientToServerEvents {
  placeBet: (data: {
    betAmount: number;
    autoCashOut: boolean;
    autoCashOutValue: number | null;
    betRefId: string;
  }) => void;
  cancelBet: (data: { betId: string }) => void;
  cashOut: (data: { betId: string; currentMultiplier: number }) => void;
  startNewRound: () => void;
}

// Singleton class to manage socket connection
class SocketManager {
  private static instance: SocketManager;
  private socket: Socket<ServerToClientEvents, ClientToServerEvents> | null =
    null;
  private connectionListeners: Set<(connected: boolean) => void> = new Set();

  private constructor() {}

  public static getInstance(): SocketManager {
    if (!SocketManager.instance) {
      SocketManager.instance = new SocketManager();
    }
    return SocketManager.instance;
  }

  public connect(userId: string) {
    if (this.socket?.connected) return;

    if (this.socket) {
      this.socket.connect();
      return;
    }

    this.socket = io(DEV_CONFIG.API.BASE_URL, {
      auth: { userId },
      reconnectionAttempts: 5,
      timeout: 10000,
    });

    this.socket.on("connect", () => {
      console.log("Socket connected");
      this.notifyConnectionListeners(true);
    });

    this.socket.on("disconnect", () => {
      console.log("Socket disconnected");
      this.notifyConnectionListeners(false);
    });
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public getSocket(): Socket<ServerToClientEvents, ClientToServerEvents> {
    if (!this.socket) {
      throw new Error("Socket not initialized. Make sure you are logged in.");
    }
    return this.socket;
  }

  public isConnected(): boolean {
    return this.socket?.connected ?? false;
  }

  public addConnectionListener(listener: (connected: boolean) => void) {
    this.connectionListeners.add(listener);
    // Immediately notify the listener of current connection status
    listener(this.isConnected());
  }

  public removeConnectionListener(listener: (connected: boolean) => void) {
    this.connectionListeners.delete(listener);
  }

  private notifyConnectionListeners(connected: boolean) {
    this.connectionListeners.forEach((listener) => listener(connected));
  }
}

// Hook to use the socket
export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const user = useAppSelector((state) => state.rootReducer.users.user);
  const socketManager = SocketManager.getInstance();
  useEffect(() => {
    if (user && user.id) {
      socketManager.connect(user.id);
    }
  });

  useEffect(() => {
    const handleConnection = (connected: boolean) => {
      setIsConnected(connected);
    };

    socketManager.addConnectionListener(handleConnection);

    return () => {
      socketManager.removeConnectionListener(handleConnection);
    };
  }, []);

  const placeBet = useCallback(
    (data: {
      betAmount: number;
      autoCashOut: boolean;
      autoCashOutValue: number | null;
      betRefId: string;
    }) => {
      socketManager.getSocket().emit("placeBet", data);
    },
    []
  );

  const cashOut = useCallback(
    (data: { betId: string; currentMultiplier: number }) => {
      socketManager.getSocket().emit("cashOut", data);
    },
    []
  );

  const cancelBet = useCallback((data: { betId: string }) => {
    socketManager.getSocket().emit("cancelBet", data);
  }, []);

  const startNewRound = useCallback(() => {
    socketManager.getSocket().emit("startNewRound");
  }, []);

  return {
    socket: socketManager.getSocket.bind(socketManager),
    isConnected,
    placeBet,
    cancelBet,
    cashOut,
    startNewRound,
  };
};

// Example usage in your app
export const initializeSocket = (userId: string) => {
  const socketManager = SocketManager.getInstance();
  socketManager.connect(userId);
};

export const disconnectSocket = () => {
  const socketManager = SocketManager.getInstance();
  socketManager.disconnect();
};
// import { useState, useEffect, useCallback } from "react";
// import { io, Socket } from "socket.io-client";
// import { useAppSelector } from "../hooks"; // Adjust this import based on your project structure
// import { DEV_CONFIG } from "../app.config";

// interface ServerToClientEvents {
//   // Define your server-to-client events here
//   gameStart: (data: { betId: string; status: string }) => void;
//   gameInProgress: (data: { betId: string }) => void;
//   preparationPhase: (data: { betId: string }) => void;
//   gameTick: (data: { betId: string; currentMultiplier: number }) => void;
//   activeBetTick: (data: { betId: string; currentMultiplier: number }) => void;
//   betPlaced: (data: {
//     dbBetId: string;
//     success: true;
//     status: string;
//     betRefId: string;
//     updatedUserWallet: number;
//   }) => void;
//   betCancelled: (data: {
//     dbBetId: string;
//     status: string;
//     refundedAmount: number;
//     updatedWalletBalance: number;
//   }) => void;

//   globalGameStart: () => void;
//   globalPreparationPhase: () => void;
//   gameEnd: (data: {
//     betId: string;
//     crashMultiplier: number;
//     status: string;
//   }) => void;
//   autoCashedOut: (data: {
//     betId: string;
//     winnings: number;
//     currentMultiplier: number;
//     status: string;
//   }) => void;
//   cashOutSuccess: (data: {
//     userId: string;
//     winnings: number;
//     currentMultiplier: number;
//   }) => void;
// }

// interface ClientToServerEvents {
//   // Define your client-to-server events here
//   placeBet: (data: {
//     betAmount: number;
//     autoCashOut: boolean;
//     autoCashOutValue: number | null;
//     betRefId: string;
//   }) => void;
//   cancelBet: (data: { betId: string }) => void;
//   cashOut: (data: { betId: string; currentMultiplier: number }) => void;
//   startNewRound: () => void;
// }

// export const useSocket = () => {
//   const [socket, setSocket] = useState<Socket<
//     ServerToClientEvents,
//     ClientToServerEvents
//   > | null>(null);
//   const [isConnected, setIsConnected] = useState(false);
//   const user = useAppSelector((state) => state.rootReducer.users.user); // Adjust this based on your Redux store structure

//   const initializeSocket = useCallback(() => {
//     if (user && user.id) {
//       const newSocket = io(DEV_CONFIG.API.BASE_URL, {
//         auth: { userId: user.id },
//         reconnectionAttempts: 5,
//         timeout: 10000,
//       });

//       newSocket.on("connect", () => {
//         console.log("Socket connected");
//         setIsConnected(true);
//       });

//       newSocket.on("disconnect", () => {
//         console.log("Socket disconnected");
//         setIsConnected(false);
//       });

//       setSocket(newSocket);

//       return () => {
//         newSocket.disconnect();
//       };
//     }
//   }, [user]);

//   useEffect(() => {
//     const cleanup = initializeSocket();
//     return cleanup;
//   }, [initializeSocket]);

//   const getSocket = useCallback(() => {
//     if (!socket) {
//       throw new Error("Socket not initialized. Make sure you are logged in.");
//     }
//     return socket;
//   }, [socket]);

//   const placeBet = useCallback(
//     (data: {
//       betAmount: number;
//       autoCashOut: boolean;
//       autoCashOutValue: number | null;
//       betRefId: string;
//     }) => {
//       getSocket().emit("placeBet", data);
//     },
//     [getSocket]
//   );

//   const cashOut = useCallback(
//     (data: { betId: string; currentMultiplier: number }) => {
//       getSocket().emit("cashOut", data);
//     },
//     [getSocket]
//   );
//   const cancelBet = useCallback(
//     (data: { betId: string }) => {
//       getSocket().emit("cancelBet", data);
//     },
//     [getSocket]
//   );

//   const startNewRound = useCallback(() => {
//     getSocket().emit("startNewRound");
//   }, [getSocket]);

//   return {
//     socket: getSocket,
//     isConnected,
//     placeBet,
//     cancelBet,
//     cashOut,
//     startNewRound,
//   };
// };
