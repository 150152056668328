import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { AuthModal } from "./AuthModal";
import { useAppSelector, useAppDispatch } from "../hooks";
import {
  logout,
  selectIsAuthenticated,
  selectUser,
  updateUserWallet,
} from "../store/slices";
import {
  FaUser,
  FaCoins,
  FaSignOutAlt,
  FaRegUser,
  FaWallet,
} from "react-icons/fa";
import { useDepositMutation, useLogoutMutation } from "../api";
import { SnackBarMode } from "../types";
import { LoadingDots } from "./LoadingDots";
import { TransactionModal } from "./TransactionModal";
import { Snackbar } from "./SnackBar";
import { BASE_CONFIG } from "../app.config";
import { disconnectSocket } from "../store/websockets-actions";

const Header: React.FC = () => {
  const user = useAppSelector(selectUser)!;
  const [logoutUser] = useLogoutMutation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [deposit, { isLoading: depositLoading }] = useDepositMutation();
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: SnackBarMode;
  }>({
    show: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleUserPanelClick = () => {
    navigate("/user-panel");
  };

  const handleDepositClick = () => {
    setShowDepositModal(true);
  };

  const handleDeposit = async ({
    amount,
    mpesaNumber,
  }: {
    amount: number;
    mpesaNumber: string;
  }) => {
    try {
      const response = await deposit({
        amount,
        mpesa_number: mpesaNumber,
        userId: user!.id.toString(),
      });

      if ("data" in response && response.data !== undefined) {
        dispatch(updateUserWallet(response.data.wallet_balance));
        setSnackbar({
          show: true,
          message: response.data.message,
          type: "success",
        });
      }
      if ("error" in response) {
        setSnackbar({ show: true, message: "Payment failed!", type: "error" });
      }
    } catch (error) {
      setSnackbar({
        show: true,
        message: "Error during deposit!",
        type: "error",
      });
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser().unwrap();
      dispatch(logout());
      disconnectSocket();
      navigate("/");
      console.log("User successfully logged out");
    } catch (error) {
      console.log("Logout failed", error);
    }
  };
  const formatBalance = (balance: number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(balance);
  };
  if (depositLoading) {
    return <LoadingDots />;
  }
  const currencyCode = BASE_CONFIG.UI.CURRENCY || "KES";
  return (
    <StyledHeader>
      <Container>
        {snackbar.show && (
          <Snackbar
            message={snackbar.message}
            type={snackbar.type}
            onClose={() => setSnackbar({ ...snackbar, show: false })}
          />
        )}
        <TransactionModal
          show={showDepositModal}
          handleClose={() => setShowDepositModal(false)}
          transactionType="deposit"
          onSubmit={handleDeposit}
        />
        <LogoContainer onClick={() => navigate("/")}>
          <LogoImage src={logo} alt="Logo" />
          <LogoText>Crash Game</LogoText>
        </LogoContainer>

        <MenuToggle onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>

        <NavMenu isOpen={isMenuOpen}>
          {isAuthenticated && (
            <NavItem onClick={() => navigate("/")}>Home</NavItem>
          )}
          <NavItem onClick={() => navigate("/demo")}>Demo</NavItem>

          {isAuthenticated ? (
            <>
              <DepositButton onClick={handleDepositClick}>
                <FaCoins /> Deposit
              </DepositButton>

              <UserDropdown>
                <UserPanelButton onClick={() => {}}>
                  <FaUser /> Profile
                </UserPanelButton>
                <DropdownContent>
                  <DropdownItem onClick={handleUserPanelClick}>
                    <FaRegUser /> Account
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout}>
                    <FaSignOutAlt /> Logout
                  </DropdownItem>
                </DropdownContent>
              </UserDropdown>
              <WalletContainer>
                <WalletIcon>
                  <FaWallet />
                </WalletIcon>
                <WalletInfo>
                  {/* <WalletLabel>Balance</WalletLabel> */}
                  <WalletBalance>
                    KES {formatBalance(user?.wallet_balance || 0)}
                  </WalletBalance>
                </WalletInfo>
              </WalletContainer>
            </>
          ) : (
            <AuthButtons>
              <AuthModal />
            </AuthButtons>
          )}
        </NavMenu>
      </Container>
    </StyledHeader>
  );
};

// Styled Components
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledHeader = styled.header`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background: linear-gradient(to right, #1a1a2e, #16213e);
  padding: 1rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(78, 204, 163, 0.2); }
  50% { box-shadow: 0 0 20px rgba(78, 204, 163, 0.4); }
  100% { box-shadow: 0 0 5px rgba(78, 204, 163, 0.2); }
`;
const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgba(78, 204, 163, 0.1);
  border-radius: 12px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border: 1px solid rgba(78, 204, 163, 0.3);
  transition: all 0.3s ease;
  animation: ${glowAnimation} 3s infinite;

  &:hover {
    background: rgba(78, 204, 163, 0.2);
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
    width: 100%;
    justify-content: center;
  }
`;

const WalletIcon = styled.div`
  color: #4ecca3;
  font-size: 1.5rem;
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
`;

const WalletInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const WalletLabel = styled.span`
  font-size: 0.75rem;
  color: #8a8f98;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const WalletBalance = styled.span`
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.5px;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4ecca3;
`;

const MenuToggle = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: #ffffff;
    margin-bottom: 4px;
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const NavMenu = styled.nav<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: #16213e;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    animation: ${fadeIn} 0.3s ease;
  }
`;

const NavItem = styled.div`
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #4ecca3;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const DepositButton = styled.button`
  display: flex;
  align-items: center;
  background: #4ecca3;
  color: #16213e;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  margin-right: 1rem;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: #45b393;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const UserDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const UserPanelButton = styled.button`
  display: flex;
  align-items: center;
  background: #4ecca3;
  color: #16213e;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background: #45b393;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background: #1a1a2e;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: none;
  flex-direction: column;

  ${UserDropdown}:hover & {
    display: flex;
  }
`;

const DropdownItem = styled.div`
  padding: 1rem;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #4ecca3;
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
`;
export { Header };
